import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Rebar Header from Below' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: EPICORE MSR Rebar Headers" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>EPICORE MSR Rebar Headers</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>The Infinity System utilizes Rebar Headers in the Epicore MSR slab for openings
            up to four or five feet clear span. This might occur at a corridor door alcove,
            or a cased opening at a hallway in an apartment unit. A Rebar Header is much
            faster and less expensive than a full-blown slab-beam or a steel WF beam.</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/steel-beams/">
                Next Page: Structural Steel Beams / Lintels
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="/system-overview/slab-beams/">
                Prev Page: Slab Beams
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/rebar-headers/gallery/01-MSRDk350_(40261).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
